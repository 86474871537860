:root {
  // FONTS ------------------------
  --font-main: 'Futura PT Book', sans-serif;
  --font-headers: 'Futura PT', sans-serif;

  // COLORS ------------------------
  --color-white: #ffffff;
  --color-black: #000000;
  --light-black: #333333;
  --bg-orange: #E5E5E5;
  --bg-body: #f5f5f5;
  --color-main: #E57857;
  --color-second: #636268;
  --color-links: #193EFF;
  --light-gray: #F0F0F0;
  --radio-gray: #DEDEDE;
  --middle-gray: #E0DFDF;
  --dark-gray: #6E9986;
  --orange-light: rgba(229, 120, 87, 0.28);;
  --pink: #D4487D;
  --green: #75A38F;
  --orange-social: #DB7353;
  --green-social: #7EA393;
  --red-require: red;
  --bg-invalid: rgb(255 0 0 / 12%);
  --bg-hover: #F9DFD7;

  // ANIMATION ------------------------
  --anim-fast: 0.15s cubic-bezier(0, 0, 0.3, 1);
  --anim-slow: 0.25s cubic-bezier(0, 0, 0.3, 1);

  // MARGINS ------------------------
  --m-outside: 12px;
  --m-inside: 28px;

  // SIZE -----------------------
  --max-w: 1024px;

  //FONT-SIZE ----------------------
  --main-title: 40px;
  --second-title: 34px;
  --custom-head-title: 38px;
  --description-title: 22px;
  --default-size: 19px;
  --default-size-body: 20px;


  --height-header: 56px;
  --shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
}

@media screen and (min-width: 1024px) {
  :root {
    //FONT-SIZE ----------------------
    --main-title: 43px;
    --second-title: 52px;
    --custom-head-title: 48px;
    --description-title: 28px;
    --default-size: 19px;
    --default-size-body: 20px;

    --height-header: 72px;
  }
}

@media screen and (max-width: 340px) {
  :root {
    //FONT-SIZE ----------------------
    --main-title: 36px;
    --second-title: 34px;
    --custom-head-title: 38px;
    --description-title: 28px;
    --default-size: 19px;
    --default-size-body: 20px;

    // MARGINS ------------------------
    --m-horizont: 15px;
  }
}



//SCSS VARIABLE
$start-media-desktop: 1024px;
$start-media-table: 768px;
$end-media-table: 1023px;
$end-media-mobile: 767px;
$end-media-mini-mob: 330px;

