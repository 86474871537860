@use "variables" as c;

.body {
  font-size: var( --default-size-body );
  padding-bottom: 40px;
  //padding: 0 var(--m-inside);
  &:not(.home__body){
    padding-top: 24px;
    @media screen and (min-width: c.$start-media-table) {
      padding-top: 60px;
    }
  }

  &-column {

    &__sidebar{
      max-width: 344px;
      display: none;
    }

    @media screen and (min-width: c.$start-media-desktop) {
      display: flex;
      justify-content: space-between;

      &__content {
        max-width: 664px;
        flex: 1;
        margin-right: 20px;
      }
      &__sidebar{
        display: block;
      }
    }

  }
  @media screen and (min-width: c.$start-media-table) {
    &__content {
      padding-right: 60px;
    }
  }

}

.m-width {
  max-width: calc(var(--max-w) + var(--m-inside) * 2);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: var(--m-inside);
  padding-right: var(--m-inside);
}

.main-title {
  font-size: var(--main-title);
  margin-top: 0;
  font-weight: bold;
  margin-bottom: 15px;
  line-height: 44px;
}

.second-title {
  font-size: var(--second-title);
  margin-top: 0;
  font-weight: bold;
  line-height: 44px;
  //margin-bottom: 22px;
}

.description-title {
  font-size: var(--description-title);
  margin-top: 0;
  font-weight: 600;
  margin-bottom: 22px;
  line-height: 30px;
  @media screen and (min-width: c.$start-media-table) {
    line-height: 34px;
  }
  //font-family: var(--font-main);
  //font-weight: bold;
}

.loading {
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  //background-color: var(--bg-light-blue);
}


.content {
  z-index: 2;

  &-wrap {
    padding-right: var(--m-horizont);
    padding-left: var(--m-horizont);
    max-width: calc(var(--max-w) + var(--m-horizont) * 2);
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    @media screen and (min-width: c.$start-media-desktop) {

    }
  }

}

.loader {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: var(--bg-orange);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.anchor {
  position: relative;
  top: -50px;
}

[class^=icon-]:before, [class*=" icon-"]:before {
  width: auto;
  margin: auto;
}

.ul-custom{
  list-style: circle;
  padding-left: 22px;
  margin-bottom: 20px;
}
