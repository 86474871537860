@use "../../assets/scss/variables" as c;

.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 3;
  box-shadow: 0 0 14px 0 rgba(35, 47, 98, 0.12);



  &__container {
    padding: 17px var(--m-inside);
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0px auto;

    @media screen and (min-width: c.$start-media-desktop) {
      flex-direction: row;
      justify-content: space-between;
      padding: 9px var(--m-inside);
      align-items: center;
      max-width: calc( var(--max-w) + var(--m-inside) * 2);
    }
  }

  &__text {
    &-wrap {
      font-size: 16px;
      padding-right: 40px;

/*      @media screen and (min-width: c.$start-media-table) and (max-width: c.$end-media-table) {
        font-size: 18px;
      }*/
    }

    line-height: 20px;

  }

  &__close {
    position: absolute;
    top: 17px;
    right: 28px;
    width: 20px;
    height: 20px;

    @media screen and (min-width: c.$start-media-desktop) {
      top: 50%;
      transform: translateY(-50%);
    }


    &:before, &:after {
      content: '';
      position: absolute;
      width: 76%;
      height: 3px;
      top: 50%;
      left: 50%;
      background-color: #7EA393;
      border-radius: 100px;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

  }

  &__btns {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: c.$start-media-desktop) {
      margin-top: 0;
      margin-right: 40px;
    }
  }

  &__btn {
    //width: 120px;
    //height: 30px;
    border-radius: 17px;
    padding: 6px 40px;
    background-color: var(--color-main);
    color: var(--color-white);


    &:hover {
      //background-color: var(--bg-btn-main-hover);
      opacity: 0.7;
    }

    @media screen and (min-width: c.$start-media-desktop) {
      margin-left: 30px;
    }

  }

  &__link {

    &-wrap{
      color: var(--color-links);
    }


    &-text {
      color: inherit;
    }
  }
}
