.form {
  position: relative;
  display: flex;
  //flex-wrap: nowrap;
  flex-direction: column;
  overflow: hidden;
  //min-height: 400px;


  &__field-wrap {
    margin-bottom: 26px;
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
  }

  &__links{
    margin-top: 26px;
  }

  &__link{
    font-size: 18px;
    color: var(--color-links);
    text-decoration: underline;
    &:hover{
      opacity: 0.8;
    }
  }

  &__label {
    color: var(--light-black);
    padding-left: var(--m-outside);
    margin-bottom: 8px;
    font-weight: 600;
    display: inline-block;
    cursor: default;

    &-require {
      color: var(--red-require);
    }
  }

  &__sub-label{
    font-size: 16px;
    margin-top: -5px;
    margin-bottom: 5px;
    line-height: 20px;
    padding-right: var(--m-outside);
    padding-left: var(--m-outside);
    color: #738e82;
  }

  &__require {
    color: var(--red-require);
  }

  &__invalid {
    &-block {
      margin-top: 20px;
      @media screen and (min-width: 1024px) {
        margin-top: 24px;
      }
      color: var(--red-require);
    }

    &-icon {
      width: 21px;
      height: 17px;
      margin-bottom: -3px;
      margin-left: 5px;
    }
  }

  &__error {
      display: inline-block;
      font-size: 16px;
      margin-top: 8px;
      color: var(--red-require)
  }

}
