@use "variables" as c;

.head-first {
  //padding-top: 30px;
  min-height: 368px;
  position: relative;
  display: flex;

  flex-direction: column;
  background-color: var(--bg-orange);

  @media screen and (min-width: c.$start-media-table) {
    align-items: center;
  }

  &__img {
    height: 240px;
  }

  .m-width{
    @media screen and (min-width: c.$start-media-table) {
      background-image: url("../../assets/svg/widgets/women.svg");
      background-repeat: no-repeat;
      background-position: 84% 14px;
      background-size: contain;
      position: relative;
    }
  }

  &__content {
    margin-top: 35px;
    margin-bottom: 34px;
    position: relative;
    z-index: 2;
    text-align: center;
    color: var(--light-black);

    @media screen and (min-width: c.$start-media-table) {
      text-align: left;
      margin-top: 74px;
      margin-bottom: 90px;

    }
  }

  &__subtitle{
    font-size: 21px;
    color: #A89E9C;
    margin-bottom: 15px;
    font-weight: 500;
    font-family: var(--font-headers);

    & > b{
      font-family: inherit;
      font-weight: inherit;
    }

    @media screen and (min-width: c.$start-media-table) {
      font-size: 26px;
      margin-bottom: 18px;
    }
  }

  &__description {
    font-size: 21px;
    margin-top: 8px;
    margin-bottom: 5px;
    font-weight: 500;
    font-family: "Futura PT", sans-serif;
    @media screen and (min-width: c.$start-media-table) {
      max-width: 548px;
      font-size: 26px;
    }
  }

  &__title{
    font-size: var(--main-title);
    margin-top: 0;
    font-weight: bold;
    margin-bottom: 3px;
    line-height: 44px;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;

    @media screen and (min-width: c.$start-media-table) {
      flex-direction: row-reverse;
      justify-content: flex-end;

      .btn{
        height: 48px;
      }
    }

    & > *:first-child{
      max-width: 320px;
      @media screen and (min-width: c.$start-media-table) {
        max-width: 230px;
      }
    }
    & > *:last-child{
      max-width: 296px;
      @media screen and (min-width: c.$start-media-table) {
        max-width: 156px;
      }

    }

    & > div{
      margin-top: 16px;
      width: 100%;
      @media screen and (min-width: c.$start-media-table) {
        margin-right: 14px;
      }
    }
  }


  @media screen and (min-width: c.$start-media-table) {
    &:not(.head-custom),&:not(.head-first)  {
      min-height: 272px;
    }
  }
}

.head-second {
  //padding-top: 30px;
  //min-height: 378px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-orange);

  @media screen and (min-width: c.$start-media-table) {
    min-height: 272px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }


  &__img {
    height: 240px;
  }


  &__title{
    span{
      color: var(--color-main);
    }
  }

  &__content {
    margin-top: 35px;
    margin-bottom: 28px;
    position: relative;
    z-index: 2;
  }

  &__description {
    margin-top: 20px;
  }

}


.head-custom {
  //min-height: 408px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-orange);



  &__content {
    margin-top: 85px;
    margin-bottom: 32px;
    position: relative;
    z-index: 2;
    @media screen and (min-width: c.$start-media-table) {
      min-height: initial;
      margin-top: 72px;
      margin-bottom: 72px;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__title {
    font-size: var(--custom-head-title);
    margin-top: 0;
    font-weight: bold;
    line-height: 44px;
    margin-bottom: 20px;
  }

  &__description {
    margin-top: 20px;
    font-size: 20px;

    p {
      line-height: inherit;
    }
  }


}