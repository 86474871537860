
@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/fonts/FuturaPT-HeavyObl.eot');
  src: url('../../assets/fonts/FuturaPT-HeavyObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-HeavyObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-HeavyObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-HeavyObl.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/fonts/FuturaPT-BoldObl.eot');
  src: url('../../assets/fonts/FuturaPT-BoldObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-BoldObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-BoldObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-BoldObl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/fonts/FuturaPT-MediumObl.eot');
  src: url('../../assets/fonts/FuturaPT-MediumObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-MediumObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-MediumObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-MediumObl.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Cond Book';
  src: url('../../assets/fonts/FuturaPTCond-Book.eot');
  src: url('../../assets/fonts/FuturaPTCond-Book.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPTCond-Book.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPTCond-Book.woff') format('woff'),
  url('../../assets/fonts/FuturaPTCond-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Demi';
  src: url('../../assets/fonts/FuturaPT-Demi.eot');
  src: url('../../assets/fonts/FuturaPT-Demi.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-Demi.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-Demi.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Book';
  src: url('../../assets/fonts/FuturaPT-Book.eot');
  src: url('../../assets/fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-Book.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-Book.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/fonts/FuturaPT-Light.eot');
  src: url('../../assets/fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-Light.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-Light.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Cond Extra';
  src: url('../../assets/fonts/FuturaPTCond-ExtraBoldObl.eot');
  src: url('../../assets/fonts/FuturaPTCond-ExtraBoldObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPTCond-ExtraBoldObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPTCond-ExtraBoldObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPTCond-ExtraBoldObl.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Book';
  src: url('../../assets/fonts/FuturaPT-BookObl.eot');
  src: url('../../assets/fonts/FuturaPT-BookObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-BookObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-BookObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-BookObl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Cond';
  src: url('../../assets/fonts/FuturaPTCond-Bold.eot');
  src: url('../../assets/fonts/FuturaPTCond-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPTCond-Bold.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPTCond-Bold.woff') format('woff'),
  url('../../assets/fonts/FuturaPTCond-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Cond Extra';
  src: url('../../assets/fonts/FuturaPTCond-ExtraBold.eot');
  src: url('../../assets/fonts/FuturaPTCond-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPTCond-ExtraBold.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPTCond-ExtraBold.woff') format('woff'),
  url('../../assets/fonts/FuturaPTCond-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/fonts/FuturaPT-Heavy.eot');
  src: url('../../assets/fonts/FuturaPT-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-Heavy.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-Heavy.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/fonts/FuturaPT-Bold.eot');
  src: url('../../assets/fonts/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-Bold.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-Bold.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Cond';
  src: url('../../assets/fonts/FuturaPTCond-Medium.eot');
  src: url('../../assets/fonts/FuturaPTCond-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPTCond-Medium.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPTCond-Medium.woff') format('woff'),
  url('../../assets/fonts/FuturaPTCond-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/fonts/FuturaPT-LightObl.eot');
  src: url('../../assets/fonts/FuturaPT-LightObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-LightObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-LightObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-LightObl.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Cond';
  src: url('../../assets/fonts/FuturaPTCond-BoldObl.eot');
  src: url('../../assets/fonts/FuturaPTCond-BoldObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPTCond-BoldObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPTCond-BoldObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPTCond-BoldObl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Demi';
  src: url('../../assets/fonts/FuturaPT-DemiObl.eot');
  src: url('../../assets/fonts/FuturaPT-DemiObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-DemiObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-DemiObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-DemiObl.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Extra';
  src: url('../../assets/fonts/FuturaPT-ExtraBoldObl.eot');
  src: url('../../assets/fonts/FuturaPT-ExtraBoldObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-ExtraBoldObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-ExtraBoldObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-ExtraBoldObl.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Extra';
  src: url('../../assets/fonts/FuturaPT-ExtraBold.eot');
  src: url('../../assets/fonts/FuturaPT-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-ExtraBold.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-ExtraBold.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Cond Book';
  src: url('../../assets/fonts/FuturaPTCond-BookObl.eot');
  src: url('../../assets/fonts/FuturaPTCond-BookObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPTCond-BookObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPTCond-BookObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPTCond-BookObl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/fonts/FuturaPT-Medium.eot');
  src: url('../../assets/fonts/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPT-Medium.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPT-Medium.woff') format('woff'),
  url('../../assets/fonts/FuturaPT-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura PT Cond';
  src: url('../../assets/fonts/FuturaPTCond-MediumObl.eot');
  src: url('../../assets/fonts/FuturaPTCond-MediumObl.eot?#iefix') format('embedded-opentype'),
  url('../../assets/fonts/FuturaPTCond-MediumObl.woff2') format('woff2'),
  url('../../assets/fonts/FuturaPTCond-MediumObl.woff') format('woff'),
  url('../../assets/fonts/FuturaPTCond-MediumObl.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

