html,
body {
  -webkit-font-smoothing: antialiased;
  width: 100%;
  height: 100%;
}

body,
body * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: var(--font-main);
  font-weight: normal;
  color: var(--light-black);
  font-size: var(--default-size);
  background-color: var(--bg-orange);
  background-color: var(--bg-body);
  letter-spacing: 0.01em;
  line-height: 28px;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background-color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  color: inherit;
}

input {
  padding: 0;
  border: none;
  outline: none;
  font-size: inherit;
  background-color: inherit;

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

textarea, select {
  padding: 0;
  border: none;
  outline: none;
  font-size: inherit;
  background-color: inherit;
  -webkit-appearance: none;
}

select, label{
  cursor: pointer;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-family: var(--font-headers);
}

p{
  margin: 0;
  font-size: inherit;
}

figure {
  margin: 0;
  line-height: 0;
}

img {
  max-width: 100%;
  display: inline-block;
}

p:not(:last-of-type) {
  margin-bottom: 20px;
}

p {
  //line-height: 20px;
  line-height: 1.3;
}

svg {
  max-width: 100%;
  max-height: 100%;
}
