@use "src/assets/scss/variables" as c;

.wt {
  background-color: var(--color-white);
  border-radius: 6px;
  margin-bottom: 16px;
  box-shadow: var(--shadow);
  overflow: hidden;



  &__container{
    display: inline-block;
    padding: 36px 32px;
    min-height: 304px;
    transition: var(--anim-slow);
    position: relative;
  }

  &__title{
    font-size: 29px;
    line-height: inherit;
    margin-bottom: 13px;
  }

  &__dc{
    line-height: 24px
  }

  @media screen and (min-width: c.$start-media-table) {
    &:hover {
      opacity: 0.8;
    }
  }

}
